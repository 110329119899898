import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HiCloudUpload } from "react-icons/hi";
import toast from "react-hot-toast";
import { PuffLoader, PulseLoader } from "react-spinners";
import { PiHandshakeBold } from "react-icons/pi";
import { AppDispatch } from "../../store";
import { Cloudinary } from "../../store/service/cloudinary";
import { uploadInvoiceKyc, uploadKyc } from "../../store/service/auth";
import countries from "../../utils/countries.json";
import { isValidEmail } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { routeObj } from "../../constants/routes";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export default function UploadKyc() {
  const [country, setCountries] = useState<Array<string>>();
  const [backView, setBackView] = useState<File[]>([]);
  const [frontView, setFrontView] = useState<File[]>([]);
  const [selectedCountry, setSelectedCoutry] = useState("");
  const [email, setEmail] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      if (backView.length && frontView.length && selectedId) {
        setLoading(true);
        const front_view = await Cloudinary(frontView[0]);
        const back_view = await Cloudinary(backView[0]);
        if (frontView && backView) {
          const { message } = await dispatch(
            uploadInvoiceKyc({ name: selectedId, front_view, back_view, email })
          ).unwrap();
          setLoading(false);
          navigate(routeObj.landing);
          toast.success("Thank You For Investing With Us. ");
        }
      }
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      setCountries(Object.keys(countries));
    } catch (error) {
      console.log(error as TypeError);
    }
  }, []);

  useEffect(() => {
    if (backView.length && frontView.length) {
      return setStepTwo(true);
    }
    setStepTwo(false);
  }, [backView, frontView]);

  //   console.log(!selectedCountry && !user?.country);

  return (
    <>
      <div className=" bg-white/50 h-[100vh] md:h-[80vh] dash_webkit  overflow-scroll md:bg-white pt-4    rounded-lg ">
        <div className=" mx-auto  bg-green-00 w-fit  mt-10  flex justify-center items-center  ">
          <div
            className={`relative border-b-[3px] w-[130px] ${
              stepOne ? "border-main" : ""
            }   `}
          >
            <div
              className={`h-3 w-3 ${
                stepOne ? " bg-main" : "bg-slate-400"
              } absolute -bottom-[7px] rounded-full `}
            ></div>
            <div className="   absolute -top-[32px] text-sm font-bold -left-5 rounded-full ">
              Step 1
            </div>
          </div>
          <div
            className={`relative border-b-[3px] w-[130px] ${
              stepOne && stepTwo ? "border-main" : ""
            }   `}
          >
            <div
              className={`h-3 w-3 ${
                stepOne && stepTwo ? " bg-main" : "bg-slate-400"
              } absolute -bottom-[7px] rounded-full `}
            ></div>
            <div className="   absolute -top-[32px] text-sm font-bold -left-5 rounded-full ">
              Step 2
            </div>
          </div>
          <div className="relative  border-black  ">
            <div
              className={`h-3 w-3 ${
                stepOne && stepTwo && stepThree ? " bg-main" : "bg-slate-400"
              } absolute -bottom-[7px] -left-1 rounded-full`}
            ></div>
            <div className=" text-nowrap  absolute -top-[32px] text-sm font-bold -left-5 rounded-full ">
              Step 3
            </div>
          </div>
        </div>
        <div className="w-[90%] md:w-[80%] mx-auto flex flex-col gap-3 py-6 bg-red-00 ">
          <h2 className="text-center text-[16px] md:text-[30px] font-semibold ">
            {" "}
            Upload a proof of your identity
          </h2>
          <h2 className="text-center text-[12px] md:text-[15px]  ">
            {" "}
            Ark Investments Management Limited requires a valid government
            issued ID (drivers license, national ID)
          </h2>
          <div className="flex  flex-col md:flex-row w-full mt-5 mx-auto gap-7 items-center">
            <div className=" w-full ">
              <p className=" text-sm font-bold pb-2 "> Your Country</p>
              <select
                className=" p-2 w-full md:min-w-[250px]  outline-none disabled:cursor-not-allowed disabled: text-[13px] border-[#e5e5e5] border-[1px] rounded-md "
                value={selectedCountry}
                onChange={(e) => {
                  setSelectedCoutry(e.target.value);
                }}
                name="country"
              >
                <option value="">Select Country</option>
                {country?.map((item, index) => (
                  <option
                    value={item}
                    key={`country_${index}`}
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      marginLeft: "10px",
                    }}
                  >
                    {item}
                  </option>
                ))}
              </select>
            </div>

            <div className=" w-full ">
              <p className=" text-sm font-bold pb-2 "> Document Type</p>
              <select
                className=" p-2 w-full md:min-w-[250px] outline-none text-[13px] border-[#e5e5e5] border-[1px] rounded-md "
                onChange={(e) => {
                  if (e.target.value) {
                    if (selectedCountry) {
                      setStepOne(true);
                    }
                    setSelectedId(e.target.value);
                  }
                }}
                disabled={!selectedCountry}
                value={selectedId}
              >
                <option value="">Select Document Type</option>

                {["National ID", "Driver's License"].map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-5 mt-7 ">
            <div className="App w-full flex flex-col  ">
              <div className=" text-sm pb-3 flex items-center gap-3 ">
                Front side of your document <HiCloudUpload />
              </div>
              <FilePond
                files={frontView}
                onupdatefiles={(fileItems) => {
                  setFrontView(fileItems.map((item) => item.file as File));
                }}
                allowMultiple={false}
                maxFiles={1}
                name="files"
                labelIdle='Drag & Drop Front View or <span style={ fontSize:"12px" } class="filepond--label-action">Browse</span>'
              />
            </div>

            <div className="App w-full  ">
              <div className=" text-sm pb-3 flex items-center gap-3 ">
                Back side of your document <HiCloudUpload />
              </div>

              <FilePond
                files={backView}
                onupdatefiles={(fileItems) => {
                  setBackView(fileItems.map((item) => item.file as File));
                }}
                allowMultiple={false}
                maxFiles={1}
                name="files"
                labelIdle='Drag & Drop Back View  or <span class="filepond--label-action">Browse</span>'
              />
            </div>
          </div>
          <div className=" w-full ">
            <p className=" text-sm font-bold pb-2 "> Email Address</p>
            <input
              className=" p-2 w-full md:min-w-[250px]  outline-none disabled:cursor-not-allowed disabled: text-[13px] border-[#e5e5e5] border-[1px] rounded-md "
              value={email}
              onChange={(e) => {
                if (isValidEmail(e.target.value)) {
                  setStepThree(true);
                }
                setEmail(e.target.value);
              }}
            />
          </div>
          <button
            onClick={handleSubmit}
            disabled={!stepOne || !stepTwo || !stepThree || loading}
            className=" outline-none rounded-lg mt-4 mb-20  disabled:bg-main/50 px-8 py-3 disabled:cursor-not-allowed bg-main text-white font-bold "
          >
            {loading ? <PulseLoader /> : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
}
