import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import { useLocation, useParams } from "react-router-dom";
import { AppDispatch } from "../../../store";
import { getTransactions } from "../../../store/service/adminApi";
import { Fmtd_Date } from "../../../utils/date";
import { numberFormatter } from "../../../utils/functions";
import Pagination from "../../../components/Pagination";
import { SelectAdmin } from "../../../store/slice/admin";

export default function ViewTransactions() {
  const dispatch = useDispatch<AppDispatch>();
  const { transactions, loading, pagination } = useSelector(SelectAdmin);
  const { id } = useParams();

  const FetchTransactions = async (
    params: { page: number; id: string } | null
  ) => {
    try {
      await dispatch(getTransactions(params));
    } catch (error) {
      console.log(error);
    }
  };

  const sendRequest = (val: number) => {
    return FetchTransactions({ page: val, id: id! });
  };

  useEffect(() => {
    if (id) {
      FetchTransactions({ id: id!, page: 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className=" block pt-10 w-full dash_webkit overflow-x-auto ">
      <table className=" bg-red-00 w-full md:w-full ">
        <thead className=" mb-7 ">
          <tr className=" text-left  border-b-2  border-[#d7d7d7] ">
            <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
              Previous Balance
            </th>
            <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
              Current Balance
            </th>
            <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
              Profit Added
            </th>
            <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
              Date
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-[#d7d7d7]">
          {!loading &&
            transactions?.length !== 0 &&
            transactions?.map((transaction, index) => (
              <TableRow
                key={index}
                previous_amt={transaction?.previous_amt}
                current_amt={transaction?.current_amt}
                profit_added={transaction?.profit_added}
                date={Fmtd_Date(transaction?.created_at)}
              />
            ))}

          {!loading && transactions.length === 0 && (
            <tr>
              <td className="py-0 text-center " colSpan={5}>
                <div className="w-full py-10 font-bold bg-main text-white text-[20px] flex justify-center items-center ">
                  No Transactions History
                </div>
              </td>
            </tr>
          )}

          {loading && (
            <tr>
              <td className="py-6 text-center " colSpan={5}>
                <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
                  <ScaleLoader />
                </div>
              </td>
            </tr>
          )}

          <tr>
            <td className="pt-6" colSpan={5}>
              <Pagination
                currentPage={pagination?.current}
                totalPages={pagination?.total}
                sendRequest={sendRequest}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const TableRow = memo(
  ({ profit_added, previous_amt, current_amt, date }: any) => {
    return (
      <tr className={`text-left border-b-2  border-[#d7d7d7]`}>
        <td className="py-5 whitespace-nowrap px-3">
          ${numberFormatter(previous_amt)}
        </td>
        <td className="py-5 whitespace-nowrap px-3">
          ${numberFormatter(current_amt)}
        </td>{" "}
        <td className="py-5 whitespace-nowrap px-3">
          ${numberFormatter(profit_added)}
        </td>
        <td className="py-5 whitespace-nowrap px-3">{date}</td>
      </tr>
    );
  }
);
