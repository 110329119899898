import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import { useLocation, useParams } from "react-router-dom";
import { SelectInvestment } from "../../../store/slice/investment";
import { AppDispatch } from "../../../store";
import { getDeposits } from "../../../store/service/adminApi";
import { Fmtd_Date } from "../../../utils/date";
import { numberFormatter } from "../../../utils/functions";
import Pagination from "../../../components/Pagination";
import { SelectAdmin } from "../../../store/slice/admin";

export default function ViewUsersDeposits() {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { deposit, loading, pagination } = useSelector(SelectAdmin);
  const { id } = useParams();

  const FetchDeposit = async (params: { page: number; id: string } | null) => {
    try {
      await dispatch(getDeposits(params));
    } catch (error) {
      console.log(error);
    }
  };

  const sendRequest = (val: number) => {
    return FetchDeposit({ page: val, id: id! });
  };

  useEffect(() => {
    if (id) {
      FetchDeposit({ id: id!, page: 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className=" block pt-10 w-full dash_webkit overflow-x-auto ">
      <table className=" bg-red-00 w-full md:w-full ">
        <thead className=" mb-7 ">
          <tr className=" text-left  border-b-2  border-[#d7d7d7] ">
            <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
              Deposit Id
            </th>
            <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
              Amount
            </th>
            <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
              Plan
            </th>
            <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
              Status
            </th>
            <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
              Date Of Deposit
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-[#d7d7d7]">
          {!loading &&
            deposit?.length !== 0 &&
            deposit?.map((deposit) => (
              <TableRow
                key={deposit?.id}
                deposit_id={deposit?.id}
                amount={deposit?.amount}
                plan={deposit?.plan}
                status={deposit?.status}
                date={Fmtd_Date(deposit?.created_at)}
              />
            ))}

          {!loading && deposit.length === 0 && (
            <tr>
              <td className="py-0 text-center " colSpan={5}>
                <div className="w-full py-10 font-bold bg-main text-white text-[20px] flex justify-center items-center ">
                  You Have No History Of Deposits Made
                </div>
              </td>
            </tr>
          )}

          {loading && (
            <tr>
              <td className="py-6 text-center " colSpan={5}>
                <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
                  <ScaleLoader />
                </div>
              </td>
            </tr>
          )}

          <tr>
            <td className="pt-6" colSpan={5}>
              <Pagination
                currentPage={pagination?.current}
                totalPages={pagination?.total}
                sendRequest={sendRequest}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const TableRow = memo(({ deposit_id, status, amount, plan, date }: any) => {
  return (
    <tr className={`text-left border-b-2  border-[#d7d7d7]`} key={deposit_id}>
      <td className="py-5 whitespace-nowrap px-3">{deposit_id}</td>
      <td className="py-5 whitespace-nowrap px-3">
        ${numberFormatter(amount)}
      </td>
      <td className="py-5 whitespace-nowrap px-3">{plan}</td>
      <td className="py-5 whitespace-nowrap px-3">
        <div
          className={` ${
            status === "pending"
              ? "bg-gray-500"
              : status === "declined"
              ? " bg-red-400"
              : "bg-green-500"
          } w-fit px-5 py-2 text-white rounded-xl`}
        >
          {status}
        </div>
      </td>
      <td className="py-5 whitespace-nowrap px-3">{date}</td>
    </tr>
  );
});
