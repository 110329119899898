import React, { ReactNode } from "react";
import { FaTimes } from "react-icons/fa";
import { Button } from "../../components/Button";

export default function FixedPageModal({
  children,
  closeModal,
  open,
}: {
  open: boolean;
  closeModal: () => void;
  children: ReactNode;
}) {
  return (
    <>
      {open && (
        <div
          onScroll={(e) => e.stopPropagation()}
          className="fixed flex justify-center items-center top-0 right-0 left-0 w-[100%] h-[100vh]  bg-blue-600 bg-opacity-50 backdrop-blur-md z-[99999] animate-fade-in "
        >
          <Button
            onClick={closeModal}
            className=" fixed right-5 top-5 text-[20px] "
          >
            <FaTimes />
          </Button>
          {children}
        </div>
      )}
    </>
  );
}
