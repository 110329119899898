import { FaWhatsapp } from "react-icons/fa";
import { NavUrls } from "../utils/routes";
import { FooterLink } from "./Comp_Layouts/FooterLink";
import { MdLocationOn, MdMessage, MdPhone } from "react-icons/md";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { routeObj } from "../constants/routes";

export default function Footer() {
  return (
    <div className="relative">
      <div
        // to={`https://wa.link/oh0uaq`}
        className=" block fixed left-7 p-2 z-40 rounded-full bottom-4 text-[50px] bg-green-600/60  text-white "
      >
        <FaWhatsapp />
      </div>
      <div className="px-[50px] md:py-[50px] w-full bg-black flex flex-wrap flex-col md:flex-row justify-between ">
        <div className=" flex w-[90%] md:w-[20%] shadow-2xl md:shadow-none text-white min-h-[10px] md:min-h-[300px] p-[10px] ml-[20px] mt-[30px] flex-col ">
          <p className=" pb-[10px] border-b-2 border-white text-[17px] font-bold ">
            WHO WE ARE
          </p>
          <p className=" pb-[20px] text-[14px] md:text-[16px] mt-[10px]">
            We are one of the world’s leading providers of investment, advisory
            and risk management solutions. We are a fiduciary to our clients.
            We’re investing for the future on behalf of our clients, inspiring
            our employees, and supporting our local communities.
          </p>
        </div>
        <div className="flex w-[90%] md:w-[20%] shadow-2xl md:shadow-none text-white min-h-[10px] md:min-h-[300px] p-[10px] ml-[20px] mt-[30px] flex-col ">
          <p className="pb-[10px] border-b-2 border-white text-[17px] font-bold">
            WHAT WE DO
          </p>
          <p className=" pb-[20px] text-[14px] md:text-[16px] mt-[10px]">
            We are driven by a vision of a better retirement. We believe that
            people deserve financial security across their lifetime, and that
            retirement should be within reach for everyone. To make this a
            reality, we are building better solutions, and making them
            more accessible.
          </p>
        </div>
        <div className="flex w-[90%] md:w-[20%] shadow-2xl md:shadow-none text-white min-h-[10px] md:min-h-[300px] p-[10px] ml-[20px] mt-[30px] flex-col ">
          <p className="pb-[10px] border-b-2 border-white text-[17px] font-bold">
            USEFUL LINKS
          </p>
          <div className="flex flex-col text-[14px] md:text-[16px] pt-5 ">
            {NavUrls?.map((item) => (
              <FooterLink key={item.id} {...item} />
            ))}
            <Link to={routeObj.terms}>
              <div className="w-fit bg-red-00 pb-2 flex items-center gap-5 text-white">
                <BsChevronRight /> Terms & Conditions
              </div>
            </Link>
          </div>
        </div>
        <div className=" flex flex-col w-[97%] md:w-[25%] shadow-2xl md:shadow-none text-white min-h-[300px] p-[10px] ml-[20px] md:mt-[30px]">
          <p className="pb-[10px] border-b-2 border-white text-[17px] font-bold">
            GET IN TOUCH
          </p>
          <div className="flex flex-col text-[14px] md:text-[16px] ">
            <div className="mt-[10px] flex items-center ">
              <MdLocationOn style={{ fontSize: "20px", marginRight: "10px" }} />
              12 John Princes Street, London, W1G 0JR
            </div>
            <div className="mt-[10px] flex items-center">
              <MdPhone style={{ fontSize: "20px", marginRight: "10px" }} />
              +44 74564 13254
            </div>
            <div className="mt-[10px] flex items-center">
              <MdMessage
                style={{
                  fontSize: "20px",
                  marginRight: "10px",
                }}
              />
              <a href="mailto:info@arkimltd.com">info@arkimltd.com</a>
            </div>
          </div>
        </div>
      </div>
      {/* <TickerTape /> */}
      <p className="bg-black text-center text-white text-[14px] md:text-[16px] py-3 ">
        copyright &copy; Ark All Rights Reserved
      </p>
    </div>
  );
}
