import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial";
import { RootState } from "../../index";
import { login, getUser } from "../../service/auth";
import { authConfig } from "../../../utils/Constants";

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser: (state, { payload }: PayloadAction<UserType>) => {
      state.user = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        if (window !== undefined) {
          localStorage.setItem(
            authConfig.storageTokenKeyName,
            payload?.data?.auth?.access_token
          );
        }
        state.user = payload?.data?.user;
        state.token = payload?.data?.auth?.access_token;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.user = payload?.data;
        state.loading = false;
      })
      .addCase(getUser.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getUser.rejected, (state, { payload }) => {
        state.loading = false;
      });
  },
});

export default authSlice.reducer;
export const SelectAuth = (state: RootState) => state.auth;
export const { setCurrentUser } = authSlice.actions;
