import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial";
import { RootState } from "../../index";
import { getDeposits, getWithdrawals } from "../../service/investment";

export const InvestmentSlice = createSlice({
  name: "investment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeposits.fulfilled, (state, { payload }) => {
        console.log(payload?.data?.list, "list of deposuts");

        state.deposit = payload?.data?.list ?? [];
        state.pagination = payload.data.pagination;
        state.loading = false;
      })
      .addCase(getDeposits.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeposits.rejected, (state) => {
        state.loading = false;
      })

      //withdrawal

      .addCase(getWithdrawals.fulfilled, (state, { payload }) => {
        state.withdrawal = payload?.data?.list ?? [];
        state.pagination = payload.data.pagination;
        state.loading = false;
      })
      .addCase(getWithdrawals.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWithdrawals.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default InvestmentSlice.reducer;
export const SelectInvestment = (state: RootState) => state.investment;
