export const copyToClipboard = (value: string) => {
  if (value) {
    navigator.clipboard.writeText(value.toString()).then(() => {
      return true;
    });
  }
  return false;
};

export const generateTableRandomColor = () => {
  const d = Math.floor(Math.random() * 5 + 1);
  switch (d) {
    case 1:
      return "#FFFFF0";
    case 2:
      return "#FFF5EE";
    case 3:
      return "#F0EAD6";
    case 4:
      return "#EDEAE0";
    case 5:
      return "#e1e1e1";
    default:
      return "#ffffff";
  }
};

export function objectToQueryString(obj: any) {
  return Object.keys(obj)
    .reduce(function (a: string[], k: string) {
      a.push(k + "=" + encodeURIComponent(obj[k]));

      return a;
    }, [])
    .join("&");
}

export const replaceSpecialCharsWithSpace = (inputString: string) => {
  // Using regular expression to replace all occurrences of _, -, and / with space
  if (inputString) {
    return inputString.replace(/[_\-/]/g, " ");
  }
};

export const trimSentence = (sentence: string, no_of_word: number) => {
  const words = sentence.split(" ");

  if (words.length <= no_of_word) {
    return sentence;
  }
  const trimmedSentence = words.slice(0, no_of_word).join(" ") + "...";

  return trimmedSentence;
};

const formatter = new Intl.NumberFormat();

export const numberFormatter = (num: number): string => {
  // Use the formatter to format the number as a string
  return formatter.format(num);
};

export const isValidEmail = (email: string) => {
  // Define the regex pattern for a valid email
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (email) {
    return pattern.test(email);
  }
  return false;
  // Test the email against the pattern
};
